// * Init
$error-color: #ff4a4a;
$warning-color: #ffd227;
$info-color: #4ac6ff;
$success-color: #40d565;

$font-title: 'Rethink Sans', sans-serif;
$font-subtext: 'Open Sans', sans-serif;
$font-text: 'Open Sans', sans-serif;

:root {
  --error-color: #{$error-color};
  --warning-color: #{$warning-color};
  --info-color: #{$info-color};
  --success-color: #{$success-color};

  // * for RGBA
  --rgb-error-color: 255, 74, 74;
  --rgb-warning-color: 255, 210, 39;
  --rgb-info-color: 74, 198, 255;
  --rgb-success-color: 64, 213, 101;

  // * Font
  --font-title: #{$font-title};
  --font-subtext: #{$font-subtext};
  --font-text: #{$font-text};
}

// * ICONS CAP
@import url("../assets/fonts/font-awesome/all.css");
@import url("../assets/fonts/linearicon/linearicon.css");
@import url("../assets/fonts/themify/themify.css");

// * Fonts
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Cairo:wght@200..1000&display=swap');

html {
  height: 100%;
}
body {
  margin: 0;
  height: 100%;
  font-family: var(--font-text);
}

.font__title {
  font-family: var(--font-title);
}
.font__subtext {
  font-family: var(--font-subtext);
}
.font__text {
  font-family: var(--font-text);
}

/**
** GENERAL
**/

@import "ngx-toastr/toastr";

[tooltip]:not([tooltip-desc="false"]) {
  position: relative;
  &::before {
    content: attr(tooltip);
    position: absolute;
    top: calc(5px + 100%);
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    max-width: 110%;
    text-align: center;
  }
}

mat-paginator {
  --mat-paginator-container-text-size: 14px;
}
.mat-table, .mat-menu-item {
  font-family: var(--font-subtext);
}
app-cap-datatable {
  mat-paginator {
    .mat-paginator-container {
      justify-content: space-between;
    }
  }
}
.pagination_table-out {
  width: auto;
  display: flex;
  flex-flow: wrap;
  gap: 0.5rem;
  list-style: none;
  .paginate_button {
    position: relative;
    display: block;
    .page-link {
      background: #f7f5f5;
      color: #818181;
      width: auto;
      min-width: 40px;
      height: 40px;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      &.active {
        background: var(--primary-color);
        color: #fff;
      }
      &:hover:not(.active):not(:disabled):not(.disabled) {
        background: var(--primary-color);
        color: #fff;
        opacity: 0.9;
      }
      &.disabled, &:disabled {
        background-color: #f7f5f5;
        color: #c9c9c9;
        pointer-events: none;
      }
    }
  }
}
.input__color {
  padding: 0;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  height: 25px;
  width: auto;
  min-width: 45px;
  cursor: pointer;
  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  &::-webkit-color-swatch {
    border: none;
    border-radius: 4px;
  }
}

.btn__back {
  display: block;
  --opacity: 1;
  --tw-bg-opacity: 1;
  --background: #000;
}

.loading_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999999999999;
  .backdrop_loading {
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .loading__content {
    display: block;
    width: auto;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 1.5rem 2rem;
    border-radius: 15px;
    text-align: center;
    p {
      display: block;
      margin: 0;
      text-align: center;
      width: 100%;
      font-weight: 500;
      font-size: 16px;
    }
  }
}

// Loading Default
.loads-ellipsis {
  margin: 0 auto;
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: var(--primary-color);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    &:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    &:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
  }
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

// Loading 2
.loadingio-spinner-dual-ball {
  width: 100px;
  height: 100px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-dualball div {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 30px;
  left: 10px;
}
.ldio-dualball div:nth-child(1) {
  background: var(--primary-color);
  animation: ldio-dualball 1s linear infinite;
  animation-delay: -0.5s;
}
.ldio-dualball div:nth-child(2) {
  background: var(--second-color);
  animation: ldio-dualball 1s linear infinite;
  animation-delay: 0s;
}
.ldio-dualball div:nth-child(3) {
  background: var(--primary-color);
  animation: ldio-dualball-o 1s linear infinite;
  animation-delay: -0.5s;
}
.ldio-dualball {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
.ldio-dualball div {
  box-sizing: content-box;
}
@keyframes ldio-dualball-o {
  0% {
    opacity: 1;
    transform: translate(0 0);
  }
  49.99% {
    opacity: 1;
    transform: translate(40px, 0);
  }
  50% {
    opacity: 0;
    transform: translate(40px, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 0);
  }
}
@keyframes ldio-dualball {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(40px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

// Loading 3
.loading-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  div {
    animation: loading_roaller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
    &:after {
      content: " ";
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: var(--third-color);
      margin: -4px 0 0 -4px;
    }
    &:nth-child(1) {
      animation-delay: -0.036s;
      &:after {
        top: 63px;
        left: 63px;
      }
    }
    &:nth-child(2) {
      animation-delay: -0.072s;
      &:after {
        top: 68px;
        left: 56px;
      }
    }
    &:nth-child(3) {
      animation-delay: -0.108s;
      &:after {
        top: 71px;
        left: 48px;
      }
    }
    &:nth-child(4) {
      animation-delay: -0.144s;
      &:after {
        top: 72px;
        left: 40px;
      }
    }
    &:nth-child(5) {
      animation-delay: -0.18s;
      &:after {
        top: 71px;
        left: 32px;
      }
    }
    &:nth-child(6) {
      animation-delay: -0.216s;
      &:after {
        top: 68px;
        left: 24px;
      }
    }
    &:nth-child(7) {
      animation-delay: -0.252s;
      &:after {
        top: 63px;
        left: 17px;
      }
    }
    &:nth-child(8) {
      animation-delay: -0.288s;
      &:after {
        top: 56px;
        left: 12px;
      }
    }
  }
}
@keyframes loading_roaller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.swal2-popup.swal2-modal.alert_table-swal {
  width: auto;
  max-width: 100%;
  .ctt_table {
    table {
      font-size: 1.075rem !important;
      vertical-align: middle !important;
      margin-bottom: 1rem;
      color: #181c32;
      border-color: #eff2f5;
      caption-side: bottom;
      border-collapse: collapse;
      thead, tbody, tfoot, tr, td, th {
        border-color: inherit;
        border-style: solid;
        border-width: 0;
      }
      thead {
        vertical-align: bottom;
        color: #b5b5c3 !important;
        font-weight: 600 !important;
        font-size: 0.95rem !important;
        text-transform: uppercase !important;
        tr {
          border-bottom-width: 1px;
          border-bottom-style: dashed;
          border-bottom-color: #eff2f5;
        }
      }
      td, th {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        padding: 0.75rem 0.75rem;
        &:first-child {
          min-width: 55px;
        }
        &:not(:first-child) {
          min-width: 100px;
        }
        &:nth-child(3) {
          min-width: 150px;
        }
        &:nth-child(7) {
          min-width: 200px;
        }
      }
      tr {
        &.danger {
          background-color: #fee2e2;
        }
        &.success {
          background-color: #dcfce7;
        }
      }
      tr:last-child, th:last-child, td:last-child {
        padding-right: 0;
      }
      tr:first-child, th:first-child, td:first-child {
        padding-left: 0;
      }
      .table.table-row-dashed tr {
        border-bottom-width: 1px;
        border-bottom-style: dashed;
        border-bottom-color: #eff2f5;
      }
      tfoot tr:last-child, tbody tr:last-child {
        border-bottom: 0 !important;
      }
    }
  }
  .two_line-hidden {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

input:focus-visible {
  outline: none !important;
}

.data_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  .btn__roller {
    display: inline-block;
    margin-left: 0;
    margin-right: 0;
    width: 25px;
    position: relative;
    --color-spin: var(--primary-color);
    --color2-spin: var(--second-color);
    --size-spin: 3px;
    &::before {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }
}
.content__loader_min {
  width: auto;
  display: block;
  max-width: 100%;
  text-align: center;
  padding: 1rem;
}
.loader__spin {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: rotate 1s linear infinite;
  &::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: var(--size-spin, 5px) solid var(--color-spin, #fff);
    animation: prixClipFix 2s linear infinite;
  }
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.loader__spin2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: rotate2 1s linear infinite;
  &::before, &::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: var(--size-spin, 5px) solid var(--color-spin, #fff);
    animation: prixClipFix2 2s linear infinite;
  }
  &::after {
    transform: rotate3d(90, 90, 0, 180deg);
    border-color: var(--color2-spin, #000);
  }
}

@keyframes rotate2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix2 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  75%, 100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
}

/* ** GENERAL - BODY ** */
body.webapp-site {
  position: relative;
  height: auto;
  margin: 0;
  min-height: 100%;
  app-root {
    display: contents;
    main-layout {
      width: 100%;
      min-height: 100%;
      display: block;
    }
  }
  #cttMain {
    padding: 1rem 0;
    width: 100%;
    margin: 0;
  }
  .content_scroll-site {
    min-height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: 1fr max-content;
    overflow: auto;
    max-width: 100%;
    max-height: 100%;
    &.not__scroll {
      grid-template-rows: minmax(0, 1fr) minmax(0, max-content);
    }
  }
  .container_cy {
    width: 1440px;
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 0 1rem;
  }
  &.swal2-height-auto {
    height: 100% !important;
  }
  .file-drop-content {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    border: 1px solid #ddd;
    border-radius: 14px;
    border-style: dashed;
    border-width: medium;
    transition: all ease-in-out 0.26s;
    &:hover {
      background-color: #f7f7f7;
    }
    &::before {
      content: "";
      display: block;
      padding-bottom: 60%;
    }
    &.type_2::before {
      padding-bottom: 38%;
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 2;
      cursor: pointer;
    }
    .description-file-after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: max-content;
      height: auto;
      max-width: 100%;
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      flex-flow: column;
      span {
        display: block;
        text-align: center;
        font-size: 14px;
        i {
          font-size: 82px;
        }
      }
    }
  }
  .content_file {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    span {
      display: block;
      padding: 1rem 1.5rem;
      background-color: #f5f5f5;
      border-radius: 8px;
      width: auto;
      max-width: 100%;
      cursor: default;
      font-size: 15px;
      font-weight: 500 !important;
      color: #5754a3 !important;
    }
    .content_options {
      display: inline-flex;
      gap: 1rem;
      flex-flow: wrap;
      margin-top: 1rem;
      button {
        appearance: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 8px 15px;
        border: 0;
        border-radius: 6px;
        font-size: 16px;
        &.file-btn_clear {
          background: #e76363;
          color: #fff;
          &:hover {
            background: #d53c3c;
          }
        }
        &.file-btn_submit {
          background: #3dc65f;
          color: #fff;
          &:hover:not(:disabled) {
            background: #2cb14d;
          }
          &:disabled {
            background-color: #b5b5c3;
          }
        }
      }
    }
  }
  .content__scroll-sidebar {
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 1rem;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 1rem;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .toast-right-top,
  .toast-left-top,
  .toast-right-bottom,
  .toast-left-bottom {
    height: auto;
  }
  .swal2-container {
    .swal2-popup {
      .swal2-icon {
        /* width: 4em; height: 4em; */
        .swal2-icon-content {
          font-size: 2.75em;
        }
      }
      .swal2-title {
        font-size: 22px;
      }
      .swal2-html-container {
        font-size: 16px;
      }
      .swal2-actions {
        .swal2-cancel {
          -webkit-box-ordinal-group: 1;
          -ms-flex-order: 0;
          order: 0;
        }
        .swal2-confirm {
          background-color: var(--primary-color);
          -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
          order: 1;
          &:focus {
            box-shadow: 0 0 0 3px rgba(var(--primary-color), 0.5);
          }
        }
        .swal2-styled {
          font-size: 16px;
        }
      }
    }
  }
  table:not(.custom-table-design):not(.mat-calendar-table) {
    border-radius: 12px;
    width: 100%;
    margin-bottom: 1rem;
    overflow: hidden;
    &.shadow {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px -1px rgba(0, 0, 0, 0.1);
    }
    thead th {
      border: 0;
      background-color: #fff;
      color: #000;
      font-weight: 600;
      font-size: 15px;
    }
    tbody tr {
      &:not(.no-border) {
        position: relative;
        &:not(:last-child) {
          &:after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            width: 100%;
            height: 1px;
            background-color: rgb(241, 241, 241);
          }
        }
      }
      td {
        font-size: 14px;
        padding-block: 4px;
      }
    }
  }
  .hide__menu-desktop {
    transition: all ease-in-out 0.5s;
  }
  .text-two-hidden {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  template-1,
  template-2,
  template-3,
  template-4,
  template-5,
  app-sidebar,
  app-sidebar-documentation,
  button-cy {
    display: contents;
  }
  .sidebar_left {
    &.active {
      transform: translateX(0);
    }
  }
  .btn_a-sidebar {
    span {
      color: var(--second-color);
      font-weight: 300;
    }
    &.active {
      background-color: var(--second-color);
      i {
        color: var(--primary-color);
      }
      span {
        color: #fff;
        font-weight: 400;
      }
      &::before {
        display: none;
      }
    }
  }
  app-dynamic-form-field {
    display: contents;
  }

  /*  ** Material Angular ** */
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    --mdc-filled-text-field-container-color: transparent;
  }
  .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
  .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
    opacity: 0.02;
  }
  .mat-mdc-tooltip-panel {
    mat-tooltip-component {
      font-size: 13px;
    }
    &.mat-mdc-tooltip-panel-below {
      .mdc-tooltip {
        overflow: unset;
        &::after {
          content: "";
          position: absolute;
          bottom: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent var(--mdc-plain-tooltip-container-color) transparent;
        }
      }
    }
    &.mat-mdc-tooltip-panel-above {
      .mdc-tooltip {
        overflow: unset;
        &::after {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: var(--mdc-plain-tooltip-container-color) transparent transparent transparent;
        }
      }
    }
    &.mat-mdc-tooltip-panel-left {
      .mdc-tooltip {
        overflow: unset;
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 100%;
          margin-top: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent transparent var(--mdc-plain-tooltip-container-color);
        }
      }
    }
    &.mat-mdc-tooltip-panel-right {
      .mdc-tooltip {
        overflow: unset;
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: 100%;
          margin-top: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent var(--mdc-plain-tooltip-container-color) transparent transparent;
        }
      }
    }
  }
  mat-header-cell {
    color: var(--second-color);
  }

  .mat-expansion-panel:not([class*="mat-elevation-z"]) {
    box-shadow: none !important;
    border-bottom: 1px solid #eaeaea;
    border-radius: 0px !important;
    padding-bottom: 8px;
  }
  .mat-expansion-indicator::after {
    border-color: #101828;
  }
  .mat-expansion-panel-content {
    background-color: #f8fafc;
    font-family: var(--font-subtext);
  }

  mat-expansion-panel-header.btn_a-sidebar {
    font-family: var(--font-subtext);
    height: auto;
    .mat-content {
      display: grid;
      grid-template-columns: minmax(0, 35px) minmax(0, 1fr) minmax(0, 35px);
      gap: 0.5rem;
    }
  }
  mat-option {
    --mat-option-selected-state-label-text-color: var(--second-color);
  }
  .mat-menu-content {
    padding: 0;
  }
  .mat-dialog-container {
    padding: 0;
    border-radius: 22px;
    .header_modal {
      padding: 1rem 1.5rem;
    }
    .content_modal {
      padding: 2rem 2.5rem;
      .content__wrapper {
        h5 {
          display: block;
          line-height: normal;
          width: 100%;
          margin: 0 0 1rem;
          font-size: 16px;
          strong {
            color: var(--primary-color);
            font-weight: 700;
          }
          span {
            color: var(--second-color);
            font-weight: 600;
          }
        }
        ul {
          padding-left: 2rem;
          list-style: none;
          display: grid;
          grid-template-columns: 100%;
          gap: 1rem;
          li {
            width: 100%;
            display: block;
            font-size: 14px;
            strong {
              margin-right: 5px;
            }
          }
        }
        &.note {
          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            a {
              color: var(--third-color);
              cursor: pointer;
              font-weight: 600;
              &:hover {
                color: var(--second-color);
              }
            }
          }
        }
      }
    }
    .actions_modal {
      padding: 1rem 1.5rem;
    }
  }
  mat-dialog-container {
    .mat-mdc-dialog-title {
      h3 {
        color: var(--second-color);
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2rem;
        border-bottom: 1px solid #d1d5db;
      }
    }
  }
  mat-calendar {
    --mat-datepicker-calendar-text-size: 12px;
  }
  .input__range {
    .mat-slider.mat-accent .mat-slider-track-fill,
    .mat-slider.mat-accent .mat-slider-thumb,
    .mat-slider.mat-accent .mat-slider-thumb-label {
      background-color: var(--primary-color);
    }
    mat-slider {
      width: 100%;
      padding: 0;
      height: 24px;
      .mat-slider-wrapper {
        top: 50%;
        transform: translateY(-50%);
        .mat-slider-thumb-label {
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0.6;
          .mat-slider-thumb-label-text {
            display: inline-block;
          }
        }
      }
    }
  }
  mat-stepper {
    &.stepper_not-step-header .mat-horizontal-stepper-header-container {
      display: none;
      opacity: 0;
      height: 0;
      width: 0;
      pointer-events: none;
      position: absolute;
    }
    .mat-horizontal-stepper-wrapper {
      width: 100%;
    }
    &.stepper_not-change-header mat-step-header.mat-horizontal-stepper-header {
      pointer-events: none !important;
    }
    &.stepper_not-space-content {
      .mat-horizontal-content-container {
        padding: 0;
      }
    }
  }
  .mat__type-2 {
    .mat-expansion-panel-content {
      background: transparent;
    }
  }
  .mat__check-option-custom {
    .icon__multiple-check {
      order: 1;
      margin-right: 0;
      margin-left: 16px;
    }
    mat-pseudo-checkbox {
      display: none;
    }
  }
  /* ** End Material Angular ** */

  .checkbox__field {
    width: auto;
    display: inline-block;
    position: relative;
    input[type="checkbox"] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
      display: block !important;
      appearance: none;
      z-index: 1;
      opacity: 0;
      cursor: pointer;
      &:checked ~ span {
        background-color: var(--primary-color, #000);
        &::before {
          transform: translate(-50%, -50%) scale(1);
        }
        &::after {
          transform: translate(-50%, -50%) rotate(10deg) scale(1);
        }
      }
    }
    span {
      width: 25px;
      height: 25px;
      display: block;
      border-radius: 0.4rem;
      background-color: #f1f1f1;
      transition: all ease-in-out 0.25s;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        transform-origin: center;
        background-color: var(--primary-color, #000);
        border-radius: 4px;
        transition: all ease-in-out 0.2s;
        width: 100%;
        height: 100%;
      }
      &::after {
        content: "\2713";
        font-size: 16px;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(10deg) scale(0);
        transform-origin: center;
        transition: all ease-in-out 0.2s;
      }
    }
  }
  .radio__field {
    width: auto;
    display: inline-block;
    position: relative;
    input[type="radio"] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      appearance: none;
      z-index: 1;
      opacity: 0;
      cursor: pointer;
      &:checked ~ span {
        background-color: var(--primary-color, #000);
        &::before {
          transform: translate(-50%, -50%) scale(1);
        }
        &::after {
          transform: translate(-50%, -50%) rotate(10deg) scale(1);
        }
      }
    }
    &.type__small {
      span {
        width: 20px;
        height: 20px;
      }
    }
    span {
      width: 25px;
      height: 25px;
      display: block;
      border-radius: 50%;
      background-color: #f1f1f1;
      transition: all ease-in-out 0.25s;
      overflow: hidden;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        transform-origin: center;
        background-color: var(--primary-color, #000);
        border-radius: 4px;
        transition: all ease-in-out 0.2s;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
      &::after {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(10deg) scale(0);
        transform-origin: center;
        transition: all ease-in-out 0.2s;
      }
    }
  }
  .switch__field {
    width: auto;
    display: inline-block;
    position: relative;
    &.switch__option {
      span {
        background-color: var(--second-color);
      }
      &:checked ~ span {
        background-color: var(--primary-color, #000);
      }
    }
    input[type="checkbox"] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      appearance: none;
      z-index: 1;
      opacity: 0;
      cursor: pointer;
      &:checked ~ span {
        background-color: var(--primary-color, #000);
        &::after {
          -webkit-transform: translateX(0%) translateY(-50%);
          -ms-transform: translateX(0%) translateY(-50%);
          transform: translateX(0%) translateY(-50%);
        }
      }
    }
    span {
      width: 55px;
      height: 30px;
      display: block;
      border-radius: 4rem;
      background-color: #f1f1f1;
      transition: all ease-in-out 0.25s;
      overflow: hidden;
      &::after {
        content: "";
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-100%, -50%);
        transform-origin: center;
        transition: all ease-in-out 0.2s;
        box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.14);
      }
    }
  }

  .ngx-json-viewer .segment .segment-main .segment-key {
    color: var(--third-color) !important;
  }
  .ngx-json-viewer .segment-type-string > .segment-main > .segment-value {
    color: #d06909 !important;
  }
  .ngx-json-viewer .segment-type-null > .segment-main > .segment-value {
    padding: 1px 5px;
    border-radius: 4px;
    background-color: #944ffd !important;
  }
  .ngx-json-viewer .segment-type-undefined > .segment-main > .segment-key {
    color: #999 !important;
  }
  .ngx-json-viewer .segment-type-undefined > .segment-main > .segment-value {
    padding: 1px 5px;
    border-radius: 4px;
  }

  .scrollbar_css {
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 4px rgba(128, 128, 128, 0.34);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--second-color);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: var(--second-color);
    }
    &.min_bar {
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px rgba(128, 128, 128, 0.34);
        border-radius: 6px;
      }
    }
  }
  
  .required {
    &::after {
      content: " *";
      color: red;
    }
  }

  .radio__btns {
    display: flex;
    flex-flow: wrap;
    gap: 5px;
    button {
      padding: 6px 15px;
      line-height: normal;
      border-radius: 3rem;
      border: 2px solid transparent;
      color: #000;
      font-size: 14px;
      transition: all ease-in-out 0.25s;
      &.status__new {
        color: #4990eb;
        &.active {
          border-color: #4990eb;
        }
      }
      &.status__convert {
        color: #13d213;
        &.active {
          border-color: #13d213;
        }
      }
      &.status__pending {
        color: #ffc400;
        &.active {
          border-color: #ffc400;
        }
      }
      &.status__cancel {
        color: #eb4949;
        &.active {
          border-color: #eb4949;
        }
      }
    }
    mat-radio-group {
      width: 100%;
      display: flex;
      flex-flow: wrap;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      border: 1px solid #ddd;
      background-color: #ddd;
      gap: 1px;
      overflow: hidden;
      mat-radio-button {
        flex: 1;
        background-color: #fff;
        font-size: 14px;
        .mdc-form-field {
          width: 100%;
          cursor: pointer;
          .mdc-label {
            padding-right: 10px;
            cursor: pointer;
            line-height: normal;
          }
        }
      }
    }
  }
  .cy__btn {
    padding: 0.75rem 1.25rem;
    border-radius: 8px;
    display: inline-flex;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
    appearance: button;
    margin: 0 !important;
    border: 1px solid transparent;
    &.btn__fit {
      width: fit-content;
      min-width: auto;
    }
    &.btn__icon-rounded {
      padding: 10px;
      border-radius: 50%;
      width: 38px;
      height: 38px;
    }
    &.mdc-button {
      height: auto;
      .mdc-button__label {
        display: inline-flex;
        align-items: center;
        gap: 0.5rem;
        font-weight: 400;
        font-size: 1rem;
        line-height: normal;
      }
      &.small__btn {
        .mdc-button__label {
          font-size: 14px;
        }
      }
      &.btn__primary {
        border-color: var(--primary-color);
        background-color: var(--primary-color);
        color: #fff;
      }
      &.btn__second {
        border-color: var(--second-color);
        background-color: var(--second-color);
        color: #fff;
      }
      &.btn__third {
        border-color: var(--third-color);
        background-color: var(--third-color);
        color: var(--second-color);
      }
      &.btn__error {
        border-color: $error-color;
        background-color: $error-color;
        color: #fff;
      }
      &.btn__success {
        border-color: $success-color;
        background-color: $success-color;
        color: #fff;
      }
      &.btn__warning {
        border-color: $warning-color;
        background-color: $warning-color;
        color: #fff;
      }
      &.btn__info {
        border-color: $info-color;
        background-color: $info-color;
        color: #fff;
      } 
      &.btn__grad {
        border-color: linear-gradient(0deg, #eaecf0, #eaecf0);
        background: linear-gradient(0deg, #eaecf0, #eaecf0);
        color: var(--second-color);
      }
      &:disabled {
        border-color: #c9c9c9 !important;
        background-color: #c9c9c9 !important;
      }
      // * outline
      &.btn__outline {
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.051);
        background-color: #fff;
      }
      &.btn__outline-primary {
        border: 2px solid var(--primary-color);
        background-color: transparent;
        color: var(--primary-color);
        .mdc-button__label {
          font-weight: 600;
        }
        &.hover_btn-outline:hover {
          background-color: var(--primary-color);
          color: var(--second-color);
        }
      }
      &.btn__outline-second {
        border: 2px solid var(--second-color);
        background-color: transparent;
        color: var(--second-color);
        .mdc-button__label {
          font-weight: 600;
        }
        &.hover_btn-outline:hover {
          background-color: var(--second-color);
          color: #fff;
        }
      }
      &.btn__outline-third {
        border: 2px solid var(--third-color);
        background-color: transparent;
        color: var(--third-color);
        .mdc-button__label {
          font-weight: 600;
        }
        &.hover_btn-outline:hover {
          background-color: var(--third-color);
          color: #fff;
        }
      }
      //* White
      &.btn__white-primary {
        border: 1px solid white;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.051);
        background-color: white;
        color: var(--primary-color);
        &:hover {
          border-color: var(--primary-color);
          background-color: var(--primary-color);
          color: white;
        }
      }
    }
    &:not(.mdc-button) {
      &.small__btn {
        font-size: 14px;
      }
      &.btn__primary {
        border-color: var(--primary-color);
        background-color: var(--primary-color);
        color: #fff;
      }
      &.btn__second {
        border-color: var(--second-color);
        background-color: var(--second-color);
        color: #fff;
      }
      &.btn__third {
        border-color: var(--third-color);
        background-color: var(--third-color);
        color: var(--second-color);
      }
      &.btn__error {
        border-color: $error-color;
        background-color: $error-color;
        color: #fff;
      }
      &.btn__success {
        border-color: $success-color;
        background-color: $success-color;
        color: #fff;
      }
      &.btn__warning {
        border-color: $warning-color;
        background-color: $warning-color;
        color: #fff;
      }
      &.btn__info {
        border-color: $info-color;
        background-color: $info-color;
        color: #fff;
      }
      &.btn__grad {
        border-color: linear-gradient(0deg, #EAECF0, #EAECF0);
        background: linear-gradient(0deg, #EAECF0, #EAECF0);
        color: var(--second-color);
      }
      &:disabled {
        border-color: #c9c9c9 !important;
        background-color: #c9c9c9 !important;
      }
      &.btn__outline {
        border: 1px solid #D0D5DD;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.051);
        background-color: #fff;
      }
    }
  }
  .full__hsize {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, auto) minmax(0, 1fr) minmax(0, auto);
    height: 100%;
    .mdc-dialog__content {
      min-height: 100%;
      max-height: 100%;
    }
  }

  .error__tg {
    font-size: 12px;
    color: var(--error-color);
  }

  .animation__item-sending {
    width: 150px;
    max-width: 100%;
    margin: 0 auto;
    display: block;
  }

  .dropdown_menu_cy-item {
    position: relative;
    .dropdown_menu_cy-action {
      text-transform: uppercase;
      color: var(--second-color);
    }
    &.is_open {
      .dropdown_menu_cy-action {
        color: var(--primary-color);
      }
      .dropdown_menu_cy-content {
        z-index: 1;
      }
    }
    &.is_closed {
      .dropdown_menu_cy-content {
        transition: opacity ease-in-out 0.15s, visibility ease-in-out 0.15s;
        opacity: 0;
        visibility: hidden;
      }
    }
    .dropdown_menu_cy-content {
      position: absolute;
      top: calc(100% + 40px);
      min-width: 300px;
      &.position__center {
        left: 50%;
        transform: translateX(-50%);
      }
      &.position__left {
        left: 0;
      }
      &.position__right {
        right: 0;
      }
      .dropdown_menu_cy-content-int {
        position: relative;
        width: 100%;
        padding: 0.5rem;
        background-color: #fff;
        color: var(--second-color);
        border-radius: 10px;
        box-shadow: 1px -5px 6px 0px rgba(15, 24, 41, 0.031),
          0px 12px 16px -4px rgba(15, 24, 41, 0.078);
        max-height: 500px;
        overflow: auto;
      }
    }
  }

  @keyframes textUp {
    0% {
      top: 120%;
    }
    100% {
      top: 50%;
    }
  }

  .custom__card-2 {
    padding: 1rem;
    border: 1px solid #eaecf0;
    border-radius: 8px;
    .content__head {
      width: 100%;
      text-align: center;
      .title__card {
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        margin: 0;
        margin-bottom: 10px;
      }
    }
    .content__desc {
      padding: 1rem 0;
      .text__card {
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        margin: 0;
      }
    }
    .content__btns {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
    }
  }

  .content__payments {
    border: 1px solid #eaecf0;
    box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;
    border-radius: 12px;
    overflow: hidden;
    .content__header {
      padding: 1rem;
      display: flex;
      flex-flow: wrap;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      .content__profile {
        background: #f9fafb;
        box-shadow: 0 2px 7px rgba(0, 0, 0, 0.051);
        border-radius: 8px;
        padding: 0.8rem 1rem;
        display: flex;
        flex-flow: wrap;
        align-items: center;
        gap: 10px;
        .card__status {
          border: 1px solid #d0d5dd;
          background-color: #fff;
          border-radius: 6px;
          padding: 3px 8px 3px 8px;
          font-size: 12px;
        }
      }
      .content__btns,
      .content__btns_1 {
        display: flex;
        flex-flow: wrap;
        align-items: center;
        gap: 10px;
      }
    }
  }

  .desc__profile {
    display: flex;
    align-items: center;
    &.type__small {
      display: grid;
      grid-template-columns: minmax(0, 35px) minmax(0, 1fr);
      .profile__img {
        width: 35px;
        height: 35px;
      }
      .profile__name {
        margin-left: 5px;
        font-size: 14px;
        .status {
          font-size: 0.65rem;
          padding: 0.2rem 0.3rem;
        }
      }
    }
    .profile__img {
      width: 50px;
      height: 50px;
      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: scale-down;
        object-position: center;
      }
    }
    .profile__name {
      font-weight: 500;
      font-size: 16px;
      color: #475467;
    }
    .card__subscription {
      width: 100%;
      display: block;
      font-size: 21px;
      font-weight: 700;
      color: var(--primary-color);
      line-height: normal;
    }
  }

  .status {
    background-color: rgba(2, 7, 71, 0.055);
    color: var(--second-color);
    font-size: 0.75rem;
    line-height: normal;
    padding: 0.4rem 0.9rem;
    border-radius: 5rem;
    width: fit-content;
    max-width: 100%;
    &.status__send,
    &.status__success,
    &.status__paid {
      color: var(--success-color);
      background-color: rgba(var(--rgb-success-color), 0.055);
    }
    &.status__sending,
    &.status__refunded {
      color: var(--info-color);
      background-color: rgba(var(--rgb-info-color), 0.055);
    }
    &.status__cancel,
    &.status__error,
    &.status__closed {
      color: var(--error-color);
      background-color: rgba(var(--rgb-error-color), 0.055);
    }
  }

  .content__card-section-2 {
    padding: 2rem 1.5rem;
    display: block;
    width: 100%;
    border-radius: 12px;
    background-color: rgba(var(--rgb-second-color), 0.8);
    background-image: url("/assets/img/general/card-section-2.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    h5 {
      font-size: 18px;
      font-family: var(--font-title);
      font-weight: 400;
      text-transform: uppercase;
      line-height: normal;
      margin: 0;
      margin-bottom: 5px;
      color: #fff;
    }
    p {
      font-size: 15px;
      font-family: var(--font-title);
      font-weight: 300;
      text-transform: uppercase;
      line-height: normal;
      margin-bottom: 10px;
      color: #fff;
    }
    .action__btn {
      font-family: var(--font-title);
      font-size: 20px;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      color: var(--primary-color);
      text-decoration: underline;
      display: inline-block;
      border: 0;
      appearance: none;
      outline: none;
      &:hover {
        color: var(--third-color);
      }
    }
  }
  .content__card-section-3 {
    padding: 1rem;
    display: block;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    display: flex;
    flex-flow: column;
  }

  .content__swiper {
    width: 100%;
    padding: 2rem 1rem;
    position: relative;
    .nav__slider {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 46px;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin: 0;
      color: var(--second-color);
      font-size: 38px;
      background: transparent;
      &.swiper-button-disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
    .prev__slider {
      left: -1.5rem;
    }
    .next__slider {
      right: -1.5rem;
    }
    .pagination__slider {
      position: relative;
      width: 100%;
      text-align: center;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 1.5rem;
      .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        margin: 5px;
        border-radius: 50%;
        background-color: var(--second-color);
        opacity: 0.5;
        display: inline-block;
        transition: all ease-in-out 0.2s;
        vertical-align: middle;
        &.swiper-pagination-bullet-active {
          opacity: 1;
          width: 12px;
          height: 12px;
        }
      }
    }
  }	

  .card-general {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    padding: 2rem 1.25rem;
    .title__card {
      color: var(--primary-color);
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.75rem;
      padding-bottom: .25rem;
      width: max-content;
      display: inline-block;
      margin: 0;
      margin-bottom: 1rem;
      position: relative;
      &::before {
        content: '';
        background-color: var(--primary-color);
        width: 60%;
        height: 2px;
        position: absolute;
        top: 100%;
        left: 0;
      }
    }
    .btns__card-account {
      width: 100%;
      display: block;
      text-align: center;
    }
  }

  // ** Payment Card
  .content__payment-card-list {
    padding-top: 1rem;
    .content__payment-card {
      margin-bottom: 1rem;
    }
  }
  .content__payment-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    background-color: var(--color-card-payment-bg, #f3f3f3);
    border-radius: 1rem;
    aspect-ratio: 1/0.55;
    min-height: 180px;
    position: relative;
    transition: all linear 0.5s;
    color: var(--color-card-payment-text, #000);
    &:hover {
      .delete__payment-card {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
      }
    }
    .first__card {
      flex: 1 1 0%;
      display: flex;
      flex-direction: column;
      position: relative;
      transition: all linear 0.3s;
      .content__logo-type-card {
        width: 100%;
        text-align: right;
        position: relative;
        z-index: 1;
        .logo__type-card {
          width: 80px;
          height: 40px;
          display: inline-block;
          transition: all linear 0.3s;
          background-color: var(--color-card-payment-bg, #f3f3f3);
          border-radius: 5px;
          svg, img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
            position: relative;
          }
        }
      }
      .number__card {
        position: absolute;
        padding: 0.8rem 0;
        font-size: 25px;
        font-weight: 600;
        text-align: left;
        width: 100%;
        line-height: normal;
        transition: all linear 0.3s;
        bottom: 0;
        left: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .detail__card {
      display: grid;
      grid-template-columns: minmax(0,1fr) minmax(0,max-content);
      transition: all linear 0.3s;
      .name__card {
        padding-right: 1rem;
        span {
          font-size: 12px;
        }
        p {
          font-size: 17px;
          font-weight: 500;
          transition: all linear 0.3s;
          line-height: normal;
        }
      }
      .date__card {
        span, abbr {
          font-size: 12px;
        }
        p {
          font-size: 17px;
          font-weight: 500;
          transition: all linear 0.3s;
          line-height: normal;
        }
      }
    }
    &.card__list, &.card__select-list:not(.selected__card) {
      min-height: auto;
      max-height: 75px;
      padding: 0.8rem 1rem;
      border-radius: 0.6rem;
      .first__card {
        .number__card {
          width: calc(100% - 45px);
          font-size: 17px;
          padding: 0.5rem;
        }
        .content__logo-type-card .logo__type-card {
          width: 45px;
          height: 28px;
        }
      }
      .detail__card {
        padding: 0 0.5rem;
        .name__card, .date__card {
          span, abbr {
            display: none;
          }
          p {
            font-size: 12px;
          }
        }
      }
    }
    &.card__select-list, &.card__normal-select {
      outline: 2px solid transparent;
      cursor: pointer;
      user-select: none;
      &:hover, &.selected__card {
        outline-color: var(--primary-color);
      }
      &.selected__card {
        cursor: auto;
      }
    }
    &.card__normal-select {
      .first__card .number__card {
        font-size: 20px;
      }
    }
  }

  .payment-card-add {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    background-color: var(--color-card-payment-bg, #f3f3f3);
    border-radius: 1rem;
    aspect-ratio: 1/0.55;
    min-height: 180px;
    position: relative;
    transition: all linear 0.5s;
    color: var(--color-card-payment-text, #000);
    font-size: 35px;
    cursor: pointer;
  }

  .content__add-payment-card {
    .content-input_group-date-card {
      .input_group-date-card-header, .input_group-date-card {
        width: 100%;
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,max-content) minmax(0,1fr);
        span {
          width: 10px;
          user-select: none;
          pointer-events: none;
          font-size: 24px;
          font-weight: 300;
          color: #ddd;
        }
        label {
          width: 100%;
          margin: 0px;
          margin-bottom: 0.25rem;
          display: block;
        }
      }
      .input_group-date-card {
        align-items: center;
        border: 1px solid #f1f1f1;
        border-radius: 8px;
        overflow: hidden;
        width: 100%;
        font-size: 1rem;
        line-height: 1.5rem;
        input {
          border: 0;
          appearance: initial;
          -webkit-appearance: initial;
          min-width: unset;
          width: 100%;
          outline: none;
        }
      }
    }
    .ctt__logo-type-card {
      width: fit-content;
      max-width: 80px;
      height: 100%;
      position: relative;
      display: block;
      padding-left: 1rem;
      svg, img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        position: relative;
      }
    }
    .content__payment-card-double-sided {
      aspect-ratio: 1/0.55;
      position: relative;
      color: var(--color-card-payment-text, #000);
      width: 400px;
      max-width: 100%;
      margin: 0 auto;
      overflow: hidden;
      .content__payment-card-front, .content__payment-card-reverse {
        transition: transform .4s ease-in-out;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 1.5rem;
        background-color: #f3f3f3;
        border-radius: 1rem;
        backface-visibility: hidden;
      }
      .content__payment-card-front {
        transform: perspective(1000px) rotateY(0deg);
      }
      .content__payment-card-reverse {
        transform: perspective(1000px) rotateY(180deg);
        .detail__card {
          flex: 1 1 0%;
          display: flex;
          align-items: center;
        }
        .first__card {
          flex: none;
        }
      }
      .first__card {
        flex: 1 1 0%;
        display: flex;
        flex-direction: column;
        position: relative;
        .content__logo-type-card {
          width: 100%;
          text-align: right;
          .logo__type-card {
            width: 80px;
            height: 40px;
            display: inline-block;
            svg, img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center;
              position: relative;
            }
          }
        }
        .content__chip-card {
          width: 100%;
          text-align: left;
          svg, img {
            display: inline-block;
            width: 54px;
            opacity: 0.7;
          }
        }
        .number__card {
          padding: 0.8rem 0;
          font-size: 25px;
          font-weight: 600;
          text-align: left;
          width: 100%;
          line-height: normal;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          &.placeholder__number {
            color: #ddd;
            font-weight: 400;
          }
        }
      }
      .detail__card {
        display: grid;
        grid-template-columns: minmax(0,1fr) minmax(0,max-content);
        align-items: center;
        .name__card {
          padding-right: 1rem;
          text-align: left;
          width: 100%;
          span {
            font-size: 12px;
          }
          p {
            font-size: 17px;
            font-weight: 500;
            line-height: normal;
            &.placeholder__name {
              color: #ddd;
              font-weight: 400;
            }
          }
        }
        .date__card {
          span, abbr {
            font-size: 12px;
          }
          p {
            font-size: 17px;
            font-weight: 500;
            line-height: normal;
          }
          .placeholder__date {
            color: #ddd;
            font-weight: 400;
          }
        }
        .cvv__card {
          width: calc(100% + (1.5rem * 2));
          text-align: right;
          background-color: rgba(0, 0, 0, 0.059);
          padding: 0;
          margin: 0 -1.5rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          p {
            font-size: 20px;
            font-weight: 600;
            line-height: normal;
            width: auto;
            display: inline-block;
            padding: 0.8rem 1rem;
            background-color: rgba(0, 0, 0, 0.071);
            min-height: 50px;
            min-width: 67px;
            &.placeholder__sc {
              color: #ddd;
            }
          }
        }
      }
      &.reversed__card {
        .content__payment-card-front {
          transform: perspective(1000px) rotateY(-180deg);
        }
        .content__payment-card-reverse {
          transform: perspective(1000px) rotateY(0deg);
        }
      }
    }
  }

  .delete__payment-card {
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all ease-in-out 0.25s;
  }

  .content__info-renov {
    width: 100%;
    padding: 1rem 1.5rem;
    background-color: rgba(var(--rgb-info-color), 0.4);
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: minmax(0,max-content) minmax(0,1fr) minmax(0,max-content);
    color: var(--second-color);
    .icon__info {
      font-size: 34px;
    }
    .content__info-info {
      width: 100%;
      display: block;
      padding-left: 1rem;
      padding-right: 1rem;
      h4 {
        font-size: 18px;
        font-weight: 700;
      }
      p {
        font-size: 14px;
      }
    }
    .content__info-btn {
      align-self: center;
    }
  }

  .resp__table {
    width: 100%;
    max-width: 100%;
    overflow: auto;
    table:not(.custom-table-design):not(.mat-calendar-table) {
      min-width: 100%;
      th {
        width: max-content;
        white-space: nowrap;
      }
    }
  }

  .content__match-event {
    display: grid;
    grid-template-columns: repeat(3, minmax(0,1fr));
    align-items: center;
    gap: 1rem;
    background-color: #f9fafb;
    border: 1px solid #F2F4F7;
    position: relative;
    border-radius: 12px;
    padding: 1rem 1.5rem;
    &.type__2 {
      grid-template-columns: minmax(0,140px) minmax(0,1fr);
      .match__event-detail-img {
        text-align: center;
        h5 {
          margin-bottom: 10px;
        }
        img {
          width: auto;
          height: auto;
          max-width: 100%;
          display: block;
          margin: 0 auto;
        }
      }
      .match__event-detail {
        .match__event-name {
          h4 {
            font-size: 24px;
            font-weight: 500;
          }
        }
      }
    }
    h4, h5 {
      font-family: var(--font-title);
      color: var(--second-color);
      text-transform: uppercase;
    }
    h5 {
      font-size: 15px;
      line-height: normal;
    }
    .match__vs-tournament-detail {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      align-items: center;
      .match__tournament {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        align-items: center;
        img {
          width: 25px;
          height: 25px;
          object-fit: contain;
          object-position: center;
        }
      }
      .match__date {
        flex: 1;
        text-align: right;
      }
    }
    .match__vs-img {
      display: grid;
      grid-template-columns: repeat(3, minmax(0,max-content));
      align-items: center;
      gap: 10px;
      justify-content: center;
      .match__team-a, .match__team-b {
        width: 50px;
        height: 50px;
        img, svg {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
          object-position: center;
          filter: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
        }
      }
      .match__team-vs {
        width: 33px;
        height: 33px;
        img {
          width: 100%;
          height: 100%;
          object-fit: scale-down;
          object-position: center;
        }
      }
    }
    .match__vs-detail {
      display: flex;
      flex-direction: column;
      .match__vs-name {
        h4 {
          font-size: 18px;
          font-weight: 500;
          span.match__team-vs {
            font-weight: 400;
            color: var(--primary-color);
            text-transform: none;
          }
        }
      }
    }
  }

  .content__ticketing {
    .title__ticketing {
      font-family: var(--font-title);
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.75rem;
      color: var(--second-color);
      text-transform: uppercase;
    }
    .cart__countdown {
      text-align: center;
      h5 {
        font-family: var(--font-title);
        font-weight: 600;
        font-size: 36px;
        letter-spacing: -0.02em;
        color: var(--third-color);
        .cart__countdown-info {
          display: inline-block;
          margin-left: 5px;
          transform: translateY(12px);
        }
      }
    }
    .cart__title-ticketing {
      color: #475467;
      position: relative;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: #EAECF0;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto 0;
      }
      span {
        position: relative;
        z-index: 2;
        background-color: #fff;
        display: inline-block;
        padding: 0 5px;
      }
    }
    .cart__ticketing {
      width: 100%;
      display: block;
      padding: 1rem;
      .no__data-cart {
        width: 100%;
        padding: 3rem 0;
        text-align: center;
        .no__data-cart-circle-icon {
          width: 105px;
          display: block;
          position: relative;
          margin: 0 auto;
          background: linear-gradient(180deg, #F9FAFB 0%, #EDF0F3 100%);
          border-radius: 50%;
          &::before {
            content: "";
            display: block;
            padding-bottom: 100%;
          }
          img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 40px;
            height: 40px;
            object-fit: contain;
            object-position: center;
          }
        }
        .no__data-cart-title {
          font-size: 16px;
          font-weight: 600;
          text-align: center;
        }
        .no__data-cart-desc {
          font-size: 13px;
          font-weight: 400;
          text-align: center;
          line-height: normal;
        }
      }
    }
    .cart__item {
      width: 100%;
      display: grid;
      grid-template-columns: minmax(0, max-content) minmax(0, 1fr) minmax(0, max-content);
      align-items: center;
      padding: 1rem 1.5rem;
      border: 1px solid #ddd;
      border-radius: 10px;
      margin-bottom: 0.6rem;
      .cart__item-image {
        width: 50px;
        position: relative;
        svg, img {
          max-width: 100%;
          display: block;
          width: auto;
          margin-left: auto;
          margin-right: auto;
        }
        .cart__item-delete {
          position: absolute;
          top: 8px;
          left: 0;
          transform: translate(-50%, -50%) scale(0.8);
        }
      }
      .cart__item-detail {
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
        h4 {
          font-size: 18px;
          font-family: var(--font-title);
          font-weight: 600;
          text-transform: uppercase;
          color: var(--primary-color);
          line-height: normal;
        }
        h5 {
          font-size: 16px;
          font-family: var(--font-title);
          text-transform: uppercase;
          color: var(--second-color);
          line-height: normal;
        }
      }
      .cart__item-quantity {
        width: auto;
        color: var(--second-color);
        font-size: 16px;
        font-family: var(--font-title);
        text-transform: uppercase;
        .cart__item-quantity-x {
          color: var(--primary-color);
          text-transform: none;
        }
      }
    }
    .cart__total {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.8rem 1rem;
      border: 1px solid #ddd;
      border-radius: 10px;
      background-color: #f7f7f7;
      text-align: right;
      h5 {
        font-size: 16px;
        font-family: var(--font-title);
        text-transform: uppercase;
        color: var(--second-color);
        line-height: normal;
      }
    }
  }

  .content__ticket {
    background: linear-gradient(0deg, #F2F4F7, #F2F4F7), linear-gradient(0deg, #F9FAFB, #F9FAFB);
    border: 1px solid #F2F4F7;
    position: relative;
    border-radius: 8px;
    padding: 1rem;
    text-align: center;
    .sector__img {
      width: 70px;
      max-width: 100%;
      display: block;
      margin: 0 auto;
      padding: 1rem 0;
    }
    h5 {
      font-size: 18px;
      font-family: var(--font-title);
      text-transform: uppercase;
      color: var(--second-color);
    }
    h4 {
      font-size: 18px;
      font-family: var(--font-title);
      font-weight: 600;
      text-transform: uppercase;
      color: var(--primary-color);
    }
    p {
      font-size: 12px;
      color: #667085;
      font-weight: 400;
    }
    .content__ticket-btns {
      margin: 10px 0;
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      border: 1px solid #D0D5DD;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.051);
      .btn__minus, .btn__plus, input {
        width: 100%;
        appearance: initial;
        position: relative;
      }
      .btn__minus, .btn__plus {
        font-size: 20px;
        padding: 1rem;
        text-align: center;
        line-height: normal;
        b {
          font-weight: 500;
        }
      }
      input {
        padding: 1rem;
        text-align: center;
        border: 0;
        border-left: 1px solid #D0D5DD;
        border-right: 1px solid #D0D5DD;
        color: var(--second-color);
        font-weight: 600;
      }
    }
  }

  .sidebar__acquire {
    position: sticky;
    top: 0;
  }

  .ctt__checkout-divisor {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 2rem;
  }

  .loader__spin3 {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: var(--second-color) var(--second-color) transparent transparent;
    box-sizing: border-box;
    animation: rotationSpin3 1s linear infinite;
  }
  .loader__spin3::after,
  .loader__spin3::before {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent var(--primary-color) var(--primary-color);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBackSpin3 0.5s linear infinite;
    transform-origin: center center;
  }
  .loader__spin3::before {
    width: 32px;
    height: 32px;
    border-color: var(--second-color) var(--second-color) transparent transparent;
    animation: rotationSpin3 1.5s linear infinite;
  }
      
  @keyframes rotationSpin3 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
  @keyframes rotationBackSpin3 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }

  .content__tickets-by-sector-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  .content__shop {
    width: 100%;
    display: block;
    position: relative;
    .content__shop-events {
      .shop__events-item {
        overflow: hidden;
        background-image: url('/assets/img/general/bg-tickets.png');
        background-position: center;
        background-size: cover;
        margin-bottom: 2rem;
        padding: 0;
        width: 100%;
        display: grid;
        grid-template-columns: minmax(0,max-content) minmax(0,1fr);
        background-color: #1A356A;
        .shop__events-item-event-detail-btns {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-end;
          margin-top: 2rem;
          .buy__tickets.cy__btn.mdc-button {
            text-transform: uppercase;
            font-family: var(--font-title);
            .mdc-button__label {
              font-size: 1.2rem;
            }
          }
        }
        .shop__events-item-event-detail {
          padding: 1rem 1.5rem;
          color: white;
          h3 {
            font-family: "Anton", sans-serif;
            font-size: 70px;
            line-height: 1.2;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 2px;
          }
          h5 {
            font-family: var(--font-title);
            font-size: 45px;
            text-transform: uppercase;
            line-height: 1.2;
            letter-spacing: -2px;
            margin-bottom: 2rem;
          }
          p {
            // font-family: 'Cairo', sans-serif;
            font-family: "Oswald", sans-serif;
            font-size: 28px;
            line-height: normal;
            color: white;
            text-transform: uppercase;
            letter-spacing: -0.5px;
            max-width: 100%;
          }
        }
        .shop__events-item-event-img {
          position: relative;
          width: 365px;
          max-width: 100%;
          &.center__img {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        &.type__match {
          .shop__events-item-event-img {
            padding: 1rem;
            align-self: center;
            .team-a {
              text-align: left;
            }
            .team-b {
              text-align: right;
            }
            .team-a, .team-b {
              width: 100%;
              img {
                width: 150px;
                max-width: 100%;
                display: inline-block;
                aspect-ratio: 1;
                object-fit: scale-down;
                object-position: center;
              }
            }
            .team-vs {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 60px;
              z-index: 1;
            }
          }
        }
        &.type__event-img {
          display: flex;
          align-items: center;
          justify-content: center;
          background: transparent;
          position: relative;
          .shop__events-item-event-detail-btns {
            position: absolute;
            bottom: 14%;
            right: 1.5%;
          }
        }
      }
    }
  }

  .img__bg-nf {
    background-image: url('/assets/img/figure.svg');
  }

  .title__page {
    font-family: var(--font-title);
    font-size: 45px;
    color: var(--second-color);
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    display: block;
    width: 100%;
    margin: 0 0 2rem 0;
    line-height: normal;
  }

  // ** --------Responsive

  // md tailwind
  @media screen and (min-width: 768px) {
    swal2-popup.swal2-modal.alert_table-swal ctt_table table {
      width: 100%;
    }
    .card-general {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
  }

  // sm Tailwind
  @media screen and (min-width: 640px) {

  }

  @media screen and (min-width: 361px) and (max-width: 767px) {

  }

  @media screen and (min-width: 1024px) {
    // Tailwind lg
    &.hide__menu {
      .hide__menu-desktop {
        grid-template-columns: minmax(0,0) minmax(0,1fr);
      }
    }
  }

  @media screen and (max-width: 1279px) {
    .content__shop .content__shop-events .shop__events-item {
      &.type__match {
        .shop__events-item-match .shop__events-item-vs h3 {
          font-size: 55px;
        }
        .shop__events-item-desc p {
          font-size: 22px;
        }
      }
      &.type__event {
        .shop__events-item-event-detail  {
          h3 {
            font-size: 55px;
          }
          h5 {
            font-size: 35px;
          }
          p {
            font-size: 22px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 991px){
    .content__shop .content__shop-events .shop__events-item{
      &.type__match {
        .shop__events-item-match .shop__events-item-vs h3 {
          width: 100%;
          small {
            display: block;
            width: 100%;
          }
        }
        .shop__events-item-desc .buy__tickets.cy__btn.mdc-button .mdc-button__label {
          font-size: 1rem;
        }
      }
      .shop__events-item-event-detail-btns {
        .buy__tickets.cy__btn.mdc-button .mdc-button__label {
          font-size: 1rem;
        }
      }
      &.type__event-img {
        .shop__events-item-event-detail-btns {
          right: 0;
          left: 0;
          margin: auto;
          justify-content: center;
        }
      }
    }
    .content__match-event {
      h5 {
        font-size: 15px;
      }
      .match__tournament {
        img {
          width: 25px;
          height: 25px;
        }
      }
      .match__vs-img {
        .match__team-a, .match__team-b {
          width: 50px;
          height: 50px;
        }
        .match__team-vs {
          width: 33px;
          height: 33px;
        }
      }
      .match__vs-detail {
        .match__vs-name h4 {
          font-size: 18px;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .content__shop .content__shop-events .shop__events-item{
      &.type__match {
        .shop__events-item-match {
          display: flex;
          gap: 1rem;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          .shop__events-item-vs h3 {
            font-size: 45px;
          }
          .team-a, .team-b {
            .team__img-extra {
              width: 80%;
            }
          }
        }
      }
      &.type__event {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;    
        .shop__events-item-event-detail  {
          h3 {
            font-size: 45px;
          }
          h5 {
            font-size: 30px;
          }
        }
      }
    }
    .content__match-event {
        grid-template-columns: minmax(0, 1fr);
        .match__vs-detail {
            text-align: center;
        }
        .match__tournament {
            img {
              width: 30px;
              height: 30px;
            }
        }
        .match__vs-img {
            .match__team-a, .match__team-b {
                width: 80px;
                height: 80px;
            }
            .match__team-vs {
                width: 50px;
                height: 50px;
            }
        }
    }
    .img__bg-nf {
      background-image: url('/assets/img/figure-resp.svg');
    }
    .title__page {
      font-size: 2.25rem;
    }
  }

  @media screen and (max-width: 639px) {
    .cdk-overlay-container .cdk-overlay-connected-position-bounding-box {
      background-color: rgba(0, 0, 0, 0.36);
      width: 100% !important;
      right: 0 !important;
      left: 0 !important;
      .cdk-overlay-pane {
        position: unset;
        margin: auto;
      }
    }
    .content__payment-card {
      aspect-ratio: 1/0.65;
      .first__card {
        .number__card {
          font-size: 17px;
        }
        .content__logo-type-card .logo__type-card {
          width: 70px;
          height: 35px;
        }
      }
      .detail__card {
        .name__card, .date__card {
          span {
            font-size: 10px;
          }
          p {
            font-size: 14px;
          }
        }
      }
      &.card__list, &.card__select-list:not(.selected__card) {
        .first__card {
          .number__card {
            width: calc(100% - 40px);
            font-size: 14px;
          }
          .content__logo-type-card .logo__type-card {
            width: 40px;
            height: 23px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 512px){
    .content__shop .content__shop-events .shop__events-item{
      &.type__match {
        .shop__events-item-match {
          .shop__events-item-vs h3 {
            font-size: 30px;
          }
          .team-a, .team-b {
            width: 150px;
          }
        }
        .shop__events-item-desc p {
          font-size: 19px;
        }
      }
      &.type__event {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;    
        .shop__events-item-event-detail  {
          h3 {
            font-size: 30px;
          }
          h5 {
            font-size: 20px;
          }
          p {
            font-size: 18px;
          }
        }
      }
    }
    .content__info-renov {
      grid-template-columns: minmax(0, max-content) minmax(0, 1fr);
      .content__info-btn {
        margin-top: 1rem;
        text-align: center;
        grid-column: 1 / -1;
      }
    }
  }

  @media screen and (min-width: 991px) {
    .container_cy.cb-auth {
      width: 915px;
    }
    mat-stepper {
      &.stepper_full-step {
        div.mat-horizontal-stepper-wrapper, div.mat-horizontal-content-container, div.mat-horizontal-stepper-content {
          height: 100%;
          overflow: hidden;
        }
        .mat-horizontal-content-container {
          width: 100%;
          position: relative;
          .mat-horizontal-stepper-content {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            visibility: hidden;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1280px) {
    .ctt__checkout .ctt__checkout-divisor {
      grid-template-columns: minmax(0, 360px) minmax(0, 1fr);
    }
  }

  @media screen and (min-width: 1350px) {
    .container_cy.cb-auth {
      width: 1200px;
    }
  }

  @media screen and (min-width: 1536px) {
    .container_cy.cb-auth {
      width: 1440px;
    }
  }
}